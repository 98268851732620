"use client";

import { cn } from "@/lib/utils/utils";
import { Moon, Sun } from "lucide-react";
import { useTheme } from "next-themes";
import { useEffect, useState } from "react";

export default function ThemeToggle() {
  const { theme, setTheme } = useTheme();
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted) {
      const savedTheme = localStorage.getItem("theme");

      switch (savedTheme) {
        default:

        case "dark":
          return setTheme("dark");

        case "light":
          return setTheme("light");
      }
    }
  }, [isMounted]);

  return (
    <button
      className={cn("h-8 w-8 p-1.5")}
      onClick={() => setTheme(theme === "dark" ? "light" : "dark")}
    >
      <div className="h-full w-full relative">
        <Sun
          className={cn(
            "absolute inset-0 h-[18px] w-[18px] visible rotate-0 scale-100 transition-all duration-300 origin-center",
            theme === "dark" &&
              "-rotate-90 scale-0 h-[18px] w-[18px] invisible",
          )}
        />
        <Moon
          className={cn(
            "absolute inset-0 h-[18px] w-[18px] invisible rotate-90 scale-0 transition-all duration-300 origin-center ",
            theme === "dark" && "rotate-0  scale-100  visible",
          )}
        />
      </div>
    </button>
  );
}
