import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function resolveMap(map: any, variant: any, defaultVariant: any) {
  return map[variant] ?? map[defaultVariant];
}

export function getLocalStorage<T>(key: string, defaultValue: T): T {
  if (typeof window === "undefined") return defaultValue;

  try {
    const storedValue = localStorage.getItem(key);
    // Attempt to parse the stored value as JSON to support complex objects.
    return storedValue ? (JSON.parse(storedValue) as T) : defaultValue;
  } catch (error) {
    // If parsing fails, return the default value.
    console.error(`Error parsing localStorage item "${key}":`, error);
    return defaultValue;
  }
}

export function setLocalStorage<T>(key: string, value: T): void {
  try {
    if (typeof window !== "undefined") {
      const serializedValue = JSON.stringify(value);
      localStorage.setItem(key, serializedValue);
    }
  } catch (error) {
    console.error(`Error setting localStorage item "${key}":`, error);
  }
}

export function deleteLocalStorage(key: string): void {
  if (typeof window === "undefined") return;

  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error(`Error deleting localStorage item "${key}":`, error);
  }
}

export function getAbsoluteRoute(route: string) {
  if (process.env.NEXT_PUBLIC_VERCEL_ENV === "preview") {
    return `https://${process.env.NEXT_PUBLIC_VERCEL_BRANCH_URL}${route}`;
  }

  return `${process.env.NEXT_PUBLIC_SITE_URL}${route}`;
}

export function scrollToSection({ sectionId }: { sectionId: string }) {
  document?.getElementById(sectionId)?.scrollIntoView({
    behavior: "smooth",
  });
}

export function formatDate(date: string | Date | undefined) {
  if (!date) return null;
  return new Date(date).toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
}

export const formatDateLong = (date: string | Date | undefined) => {
  if (!date) return null;
  return new Date(date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};
