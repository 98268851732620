"use client";

import { cn } from "@/lib/utils/utils";
import { SignInButton, SignedIn, SignedOut, UserButton } from "@clerk/nextjs";
import Link from "next/link";
import { usePathname } from "next/navigation";
import ThemeToggle from "../theme-toggle";

type NavbarProps = {
  style?: "solid" | "gradient";
  className?: string;
  navbarClassName?: string;
};

export default function Navbar({
  style = "solid",
  className = "",
  navbarClassName = "",
}: NavbarProps) {
  // Default style set to "gradient"
  const pathname = usePathname();

  if (pathname.includes("/chat/")) {
    return null;
  }

  // Determine navbar background style based on the style prop
  const navbarBgClass =
    style === "solid"
      ? "bg-base-100" // Style 1: Solid background
      : "bg-transparent bg-gradient-to-b from-secondary text-secondary-content"; // Style 2: Gradient background

  return (
    <nav
      className={cn(
        "w-full sticky -top-px z-20 py-4 min-h-[auto]",
        navbarBgClass, // Use the determined class here
        className,
      )}
    >
      <div
        className={cn(
          "flex items-center justify-between gap-4 max-w-screen-xl mx-auto min-h-[auto] px-3 lg:px-8",
          navbarClassName,
        )}
      >
        <div className="">
          <Link href="/" className={cn("text-xl font-semibold shrink-0")}>
            <span>💖 GlamGirls.ai</span>
          </Link>
        </div>

        <div className="flex items-center gap-2">
          <ThemeToggle />

          <SignedIn>
            <UserButton afterSignOutUrl="/" />
          </SignedIn>
          <SignedOut>
            <SignInButton mode="modal">
              <div className="font-semibold cursor-pointer text-primary hover:underline">
                Login
              </div>
            </SignInButton>
          </SignedOut>
        </div>
      </div>
    </nav>
  );
}
